import React, { useState, Fragment, useEffect } from 'react';
import { Row, Col, Container, FormGroup, Label, Input, FormText 
     , Card, CardImg, CardText, CardBody,CardGroup,
    CardTitle, CardLink, Button} from 'reactstrap';
import coffeeimg from '../../Media/coffee.jpg'
import foodimg from '../../Media/food.jpg'
import machineryimg from '../../Media/machinery.png'

function Services({ en }) {
    useEffect(() => {
    }, [en]);


    return (
        <Fragment>
            <Row className="justify-content-center Service ">
                <Col xs={10} md={10} className="info-section mt-5">

                    <Row className="justify-content-center ">
                        <Col sm={12} className="text-section text-center">
                        {en?
                        <p> OUR SERVICES</p>:
                        <p>Unsere Dienstleistungen</p>}
                        </Col>
                    </Row>

                    <Row className="justify-content-center  card-section text-left">
         <Col sm={12} md={12} lg={10} xl={10}>
         <CardGroup>
      <Card>
        <CardImg top width="100%" src={coffeeimg} alt="Card image cap" />
        <CardBody>
          <CardTitle tag="h5">Import and Export cocoa</CardTitle>         
         {/*   <CardText className="text-left  pl-3">best quality of cocoa seeds and powder and cocoa butter</CardText>*/}
       
        </CardBody>
      </Card>
      <Card>
        <CardImg top width="100%" src={foodimg} alt="Card image cap" />
        <CardBody>
          <CardTitle tag="h5">Import and Export Food</CardTitle>         
          {/*  <CardText className="text-left pl-3">Ikad Europe Company produces healthy food products and supplies high-quality organic raw materials.</CardText>*/}
         
        </CardBody>
      </Card>
      <Card>
        <CardImg top width="100%" src={machineryimg} alt="Card image cap" />
        <CardBody>
          <CardTitle tag="h5">Import and Export machinery</CardTitle>        
         {/* <CardText className="text-left  pl-3"> design and construction coordination (without own skilled manual works) as well as the purchase and sale of machinery, machine components, machine spare parts, industrial facilities and factories and the provision of general advisory servises in this area.</CardText>*/}
        
        </CardBody>
      </Card>
    </CardGroup>
         </Col>
       </Row>

                </Col>
            </Row>
        </Fragment>
    )
}

export default Services;