import React, { useState, Fragment, useEffect } from 'react';
import {
    Row, Col, Container, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import MyGreatPlace from './MyGreatPlace';

function Contect({ en }) {
    const [google, setgoogle] = useState();
    useEffect(() => {
    }, [en]);
    return (
        <Fragment>
             <Row className="justify-content-center ">
                <Col xs={10} md={10} className="info-section mt-5 contact-title">
                    {en?'We look forward to your call or message':'Wir freuen uns auf Ihren Anruf oder Ihre Nachricht'}
                </Col>
                </Row> 
            <Row className="justify-content-center ">
                <Col xs={12} md={12} lg={12} xl={10} className="info-section mt-5">

                    <Row className="justify-content-center  pt-5 pb-5 " >
                    {  en?  <Col xs={12} sm={12} md={3} className="text-center Address-section">
                         <i className="fa fa-home"></i>
                          <p className="title">Address</p>
                          <p>Teilestrasse 11-16 / Gate 4 <br />
                           12099 Berlin,Germany</p> 
                        </Col> :<Col xs={12} sm={12} md={3} className="text-center Address-section">
                           <i className="fa fa-home"></i>
                            <p className="title">Address</p>
                            <p>Teilestrasse 11-16 / Tor 4 <br />
                             12099 Berlin,Deutschland</p> 
                        </Col>
                        }
                        <Col xs={12} sm={12} md={6} className="text-section text-center border-left map-section">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyDlMfocGW7nKWTjorTMpVUB99i6a1OUp1I' }}
                                defaultCenter={{ lat: 52.459485, lng: 13.408553 }}
                                defaultZoom={16}
                            >
                                <MyGreatPlace
                                    lat={52.459485}
                                    lng={13.408553}
                                    text="IKAD EUROPE"
                                />
                            </GoogleMapReact>

                        </Col>
                    </Row> 
                    <Row className="justify-content-center ">
                        <Col md={10}className="border bottom"></Col>
                    </Row> 
                   <Row className="justify-content-center mt-3">
                        <Col xs={12} md={4} className="text-center Address-section">
                            <i className="fa fa-clock-o"></i>
                            <p className="title">Business Hours</p>
                            <p>Monday-Friday: 9am – 6pm <br />
                            Saturday: 10am – 5pm <br />
                            Sunday: Closed</p></Col>
                        <Col xs={12} md={4} className="text-center Address-section">
                            <i className="fa fa-phone"></i>
                            <p className="title">Phone</p>
                            <p> Tel.: +49 30 75001 0<br />
                                 Fax: +49 30 75001 500</p></Col>

                        <Col xs={12} md={4} className="text-center Address-section">
                            <i className="fa fa-envelope-o"></i>
                            <p className="title">Email</p>
                            <p className="email"><a href={"mailto:Info@Ikadeurope.de"}>Info@Ikadeurope.de</a></p></Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    )
}
export default Contect;
