import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router,Route, Switch,Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
/** import component for routes */

import Footer from '../ui/footer/footer';
import Header from '../ui/header/header';


import Home from './Home/Home';
import Services from './Services/Services';
import Contect from './Contect/Contect';
import Joinus from './Joinus/Joinus';

function Routes() {

    const [en, setEn] = useState(false);
  
    useEffect(() => {       
       
    }, []);
    
    return <Router >
    
        <Header en={en} setEn={setEn} />
        <Row className="justify-content-center" >
            <Col xl={12} className="p-0" >
                <Container fluid id="DynamicContent">
                    <Switch>
                           <Route exact  path="/" >
                                <Home en={en} />
                           </Route> 
                               <Route   path="/Home" >
                                <Home en={en} />
                               </Route> 
                               <Route path="/Services" >
                                <Services en={en} />
                               </Route>  
                               <Route path="/Contact" >
                                <Contect en={en} />
                               </Route> 
                               <Route path="/Joinus" >
                                <Joinus en={en} />
                               </Route> 
                    </Switch>
                </Container>
            </Col>
        </Row>
        <Footer en={en}/>
    </Router >
}

export default Routes;

