import React, { useState, Fragment } from 'react';
import {
  Row, Col, Button, Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';

import { Link, useHistory } from 'react-router-dom';
import logo from '../../Media/ikadlogo2.png'
import './header.css';

function Header({ en, setEn }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectNav, setselectNav] = useState(1);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <Fragment>
      <Row className="justify-content-center Header_Div">
     
      <Col xs={8}  md={10} >
        <Navbar dark expand="md">
        <img className="logo_img" src={logo}></img>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <Link className={selectNav === 1 ? 'nav-link active' : 'nav-link'} to="/Home" onClick={e => setselectNav(1)}>{en ? 'Home' : 'Home'}</Link>
              </NavItem>
             <NavItem>
                <Link className={selectNav === 2 ? 'nav-link active' : 'nav-link'} to="/Services" onClick={e => setselectNav(2)}>{en ? 'Service' : 'Dienstleistungen'}</Link>
            </NavItem>
              <NavItem>
                <Link className={selectNav === 3 ? 'nav-link active' : 'nav-link'} to="/Contact" onClick={e => setselectNav(3)}>{en ? 'Contact' : 'Kontakt'}</Link>
              </NavItem>
              <NavItem>
                <Link className={selectNav === 4 ? 'nav-link active' : 'nav-link'} to="/Joinus" onClick={e => setselectNav(4)}>{en ? 'Join Us' : 'Begleiten Sie Uns'}</Link>
              </NavItem>
            </Nav>

          </Collapse>
        </Navbar>
        </Col>
       
        <Col xs={4} md={2} className="text-right" >
          <Button className="btn-flag england" onClick={() => setEn(true)} ></Button>
          <Button className="btn-flag german" onClick={() => setEn(false)}></Button>
        </Col>

      </Row>
      <Row className="justify-content-center header">
      <Col xs={12}  className="header-section Home_img" >
       
         </Col>
      </Row>
    </Fragment>
  )
}

export default Header;