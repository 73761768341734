import {BrowserRouter} from 'react-router-dom';
import Routes from './routes/Routes';
import { Container } from 'reactstrap';
import './App.css';

function App() {
  return (
    <BrowserRouter > 
    <div id="AppWrapper">
        <Container fluid id="MainContainer">
         <Routes/>
        </Container>
    </div>
    </BrowserRouter > 
  );
}

export default App;
