import React, { useState, Fragment, useEffect } from 'react';
import { Row, Col, Container, FormGroup, Label, Input, FormText, Button } from 'reactstrap';
import contectImg from "../../Media/joining-team.png";

function Joinus({ en }) {
    useEffect(() => {
  }, [en]);


  return (
    <Fragment>
      <Row className="justify-content-center Joinus">
        <Col xs={10} md={10} className="info-section mt-5">
          
          <Row className="justify-content-center ">
             <Col sm={12} className="text-section text-center">
                <p className="Joinus-title"> {en?'We are looking for new employees':'Wir suchen neue Mitarbeiter'}</p>
             </Col>
          </Row>
          <Row className="justify-content-center  pt-5 pb-5 " >
           <Col xs={12} sm={12} md={6} className="text-section text-center ">
           <p>  Customer Solutions Specialist</p>
           <span className="text-left">If you're searching for a full-time, permanent work-at-home career, we’ve got an opportunity for you to join our fast-growing team! Currently, we are employing legal residents within the state of GA. H-1B Visa Sponsorship Not Available, W2 only.</span>
           </Col>
           <Col xs={12} sm={12} md={6} className="text-section text-center border-left">
           <p>  Warehouse/Material Handler</p>
           <span className="text-left">Frito-Lay Warehouse/Material Handlers are responsible for a variety of warehouse duties. A key component of PepsiCo's success, Frito-Lay has been making the very best snacks for over 80 years. Classic Lay's(r), Cheetos(r), and Doritos(r) are just a few of the products that you would help us produce in one of our manufacturing facilities. Although you will start out working for Frito-Lay, this job can open the door to career opportunities with our parent company, PepsiCo.</span>
           </Col>
         {/*  <Col xs={12} sm={12} md={4} className="text-section text-center border-left">
           <p>Junior Web Developer</p>
           <span className="text-left">You will be part of the Trust team. As a junior developer, you will help the team discover, design and implement best solutions that help our users stay safe and increase the level of trust in our platform.</span>
           </Col>*/}
           </Row>
           <Row className="justify-content-center ">
               <Col xs={10} className="border-bottom"></Col>
           </Row>
          <Row className="justify-content-center mt-1 p-5 text-left">
           <Col xs={12} md={7}>
              <p className="join-us-title">{en?'Please send your resume to':'Bitte senden Sie Ihren Lebenslauf an'}</p>
              <p className="email">Email : <a href={"mailto:Info@Ikadeurope.de"}>Info@Ikadeurope.de</a></p>
           </Col>
           <Col xs={12} md={3}>
           <img className="img-page" src={contectImg}></img>
           </Col>
          </Row>

        </Col>
        {/*  <Container className="contact-div">
             <Row className="justify-content-center  text-left ">
               <Col xs={12} md={6}>
               
                    <FormGroup>
                     <Label for="exampleEmail">{en?'First Name':'Name'}</Label>
                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                    </FormGroup>

                    <FormGroup>
                        <Label for="exampleEmail">{en?'Last Name':'Vorname'}</Label>
                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                    </FormGroup>
                
               </Col>
               <Col xs={12} md={6}>
                   <FormGroup>
                        <Label for="exampleEmail">{en?'Phone':'TelefonNummer'}</Label>
                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                    </FormGroup>

                    <FormGroup>
                        <Label for="exampleEmail">{en?'File':'File'}</Label>
                        <Input  type="file" name="file" onChange={e =>onFileChange(e)}/>
                    </FormGroup>
               </Col>
               <Col sm={12} className="text-center">
                   <Button color="info" onClick={e=>uploadFileData(e)}> Upload File</Button>
               </Col>
             </Row>
    </Container>*/}
      </Row>
    </Fragment>
  )
}

export default Joinus;