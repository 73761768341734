import React, {useEffect, Fragment } from 'react';
import { Row, Col} from 'reactstrap';

function Footer({ en }) {
    useEffect(() => {
    }, [en]);

    return (
        <Fragment>
        <Row className="justify-content-center footer-div" >       
            <Col xs={12} sm={4} xl={4} >
                    <p className="footer-p-title">IKAD EUROPE GmbH</p>
                    <p className="footer-p-text"><i className="fa fa-map-marker"></i> {en?'Teilestrasse 11-16 / Gate 4 ':'Teilestrasse 11-16 / Tor 4 '} 
                    </p>
                    <p className="footer-p-text">
                      {en?'12099 Berlin,Germany':'12099 Berlin,Deutschland'}  
                    </p>
                    <p className="footer-p-text"><i className="fa fa-phone"></i> Tel: +49 30 75001 0</p>
                    <p className="footer-p-text"> <i className="fa fa-fax"></i> Fax: +49 30 75001 500</p>
                    <p className="footer-p-text"><i className="fa fa-envelope"></i> E-Mail: Info@Ikadeurope.de</p>
            </Col>
            {en? <Col xs={12} sm={4} xl={4} >
               
            <p class="footer-p-text">Registered Office:Teilestrasse 11-16 , 12099 Berlin</p>
                    <p class="footer-p-text">Amtsgericht: Charlottenburg, HRB 93297 B</p>
                    <p class="footer-p-text">USt-IdNr. DE233965502</p>
                   {// <p class="footer-p-text">Steuernummer: 29/010/02296</p>
}
            </Col>
:<Col xs={12} sm={4} xl={4} >               
<p class="footer-p-text">Sitz der Gesellschaft:Teilestraße 11-16 , 12099 Berlin</p>
        <p class="footer-p-text">Amtsgericht: Charlottenburg, HRB 93297 B</p>
        <p class="footer-p-text">USt-IdNr. DE233965502</p>
         {// <p class="footer-p-text">Steuernummer: 29/010/02296</p>
         }
</Col>}
        </Row>
        <Row>
             <Col sm={12} className='footer-text text-center'> 
            <span>Copyright 2021 IKAD EUROPE GmbH</span>
             
            </Col>
        </Row>
        </Fragment>
    )
}

export default Footer;