import React, { useState, Fragment, useEffect } from 'react';
import {
  Row, Col, Container, Card, CardImg, CardText, CardBody,CardGroup,
  CardTitle, CardLink, Button
} from 'reactstrap';

import { Link, useHistory } from 'react-router-dom';
import businessmanimg from '../../Media/businessman.jpg'
import coffeeimg from '../../Media/coffee.jpg'
import foodimg from '../../Media/food.jpg'
import machineryimg from '../../Media/machinery.png'

function Home({ en }) {

  useEffect(() => {

  }, [en]);
  return (
    <Fragment>
      <Row className="justify-content-center text-left mt-5 ">


        <Col xs={10} sm={10} md={10} className="info-section">
          <Row>
             <Col sm={12} md={12} lg={3} className="img-section">
             <img src={businessmanimg}></img>
             </Col>
             
             <Col sm={12} md={12} lg={9} className="text-section">
             <p > {!en ? 'IKAD EUROPE - Der Vergangenheit bewusst, in der Gegenwart zuhause, der Zukunft verpflichtet...' : 'IKAD EUROPE - Aware of the past, at home in the present, committed to the future...'}</p>
              
              {!en ?<Fragment><span>Die IKAD EUROPE GmbH ist ein weltweit tätiges Handelshaus und vertritt – zum überwiegenden Teil exklusiv - die Interessen einiger in ihren Regionen führender Lebensmittel- und Getränkehersteller, Landwirtschafts- und Agrarindustrieunternehmen sowie Verpackungsmittel- und Textilproduzenten mit Schwerpunkt im Mittleren Osten, in Zentralasien, Nordafrika und der arabischen Welt.</span>


                {/* <span> Für unsere Kunden, die es zumeist bevorzugen, anonym und ungenannt zu bleiben, beschaffen wir bei namhaften, primär europäischen Herstellern und Lieferanten sämtliche erforderlichen Roh-, Hilfs- und Betriebsstoffe sowie hocheffiziente technische Anlagen von Einzelwerkzeugen, Maschinen, Maschinenteilen, Ersatz- und Austauschkomponenten bis hin zur kompletten Fertigungsstraße, kümmern uns akribisch um den vielfach ungeliebten „Papierkram“ und gewährleisten eine zeit- und kostenoptimale Gesamtlogistik.</span> */}
                <span>Wir beschaffen bei namenhaften, primär europäischen Herstellern und Lieferanten sämtliche erforderlichen Roh-, Hilfs- und Betriebsstoffe für unsere Kunden, sowie hocheffiziente technische Anlagen von Einzelwerkzeugen, Maschinen, Maschinenteilen, Ersatz- und Austauschkomponenten bis hin zur kompletten Fertigungsstraße. Zudem kümmern wir uns um die formalen Aspekte und gewährleisten eine zeit- und kostenoptimale Gesamtlogistik.</span>
                <span>Zusätzlich zu diesem klassischen, rein exportorientierten Handelsgeschäft bauen wir aktuell unsere Vertriebsaktivitäten und -Kompetenzen vehement aus und wollen unseren Industriepartnern aus dem Vorderen Orient helfen, ihre Produkte in Deutschland und in Europa bekannt(er) zu machen und diese sehr interessanten, gleichzeitig aber auch herausfordernden, wenn nicht sogar schwierigen, da hochgradig saturierten Absatzmärkte (besser) zu erschließen</span>
                <span>Unser drittes Standbein schließlich ist das allmählich, aber beständig wachsende Geschäft mit der Vermietung von Gewerbeflächen an unserem Stammsitz, der ehemaligen Sarotti-Fabrik in Berlin-Tempelhof mit seinen teilweise bis in die Anfangsjahre des 20. Jahrhunderts zurückdatierenden Industriegebäuden, die einen ganz eigenen und besonderen Charme versprühen und inzwischen unter Denkmalschutz stehen</span>
                </Fragment>:             
               <Fragment><span>IKAD EUROPE GmbH is a globally active trading house and represents - for the most part exclusively - the interests of some of the leading food and beverage manufacturers, agricultural and agro-industrial companies as well as packaging material and textile producers in their regions, with a focus on the Middle East, Central Asia and North Africa as well as the Arab world.</span>
              {/* <span>For our customers, who mostly prefer to remain anonymous and unnamed, we procure from well-known, primarily European manufacturers and suppliers all necessary raw, auxiliary and operating materials as well as highly efficient technical equipment ranging from individual tools, machines, machine parts, spare and replacement components to complete production lines, meticulously take care of the often unloved "paperwork" and ensure time- and cost-optimized total logistics.</span> */}
              <span>We procure from well-known, primarily European manufacturers and suppliers all necessary raw, auxiliary and operating materials as well as highly efficient technical equipment ranging from individual tools, machines, machine parts, spare and replacement components to complete production lines, meticulously take care of the often unloved "paperwork" and ensure time- and cost-optimized total logistics.</span>
              <span> In addition to this classical, purely export-oriented trading business, we are currently expanding our sales activities and competencies vehemently and want to help our industrial partners from the Middle East to make their products (better) known in Germany and Europe and to (further) develop these interesting, but at the same time challenging if not difficult, as highly saturated, sales markets.</span>
              <span> Finally, the third pillar is our gradually but steadily growing business with the renting of commercial space at our headquarters, the former Sarotti factory in Berlin-Tempelhof with its industrial buildings dating partly back to the early years of the 20th century, which exude a very unique and special charm and are now listed buildings.</span>
               </Fragment> } 
                
            
             </Col>
          </Row>
       
        </Col>
        <Col xs={10} sm={10} md={5} className="info-section text-center">
          <Row className="justify-content-center  border-right">
            <Col xs={12} sm={12} md={9} className="text-section">
            <p>{en ? 'Object of the company' : 'Gegenstand des Unternehmens'}</p>
              <span className="text-left" >{en ? 'Import & export,trade and logistic of food products (raw material & finished goods); purchase & sale of technical equipment(from e.g. individual tools,machines & machine parts,spare & replacemnt components up to complete production lines);marketing & renting of commercial space at the company headquarters;general consulting services in the main business areas' :
                'Import & Export,Handel Und Logistik von Lebensmitteln (Rohmaterial & Fertigprodukte); Kauf & verkauf von technischen Anlagen (von Einzelwerkzeugen, Maschinen und Maschinenteilen, Ersatz- & Austauschkomponenten bis zur kompletten Fertigunslinie); Vermarktung & Vernietung von Gewerbeflächen am Firmensitz; Allgemeine Beratungsleistungen in den Haupgeschäftsbereichen'
              }</span>
            </Col>
          </Row>
        
        </Col>
        <Col xs={10} sm={10} md={5} className="info-section text-center">
        <Row className="justify-content-center">
            <Col xs={12} sm={12} md={9} className="text-section">
            <p> {en ? 'Company name and Registered office' : 'Firma und Sitz'}</p>
            {en ?  <span className="text-left" >The company was formed on 19<sup>th</sup> December 2003 unser the legal name "IKAD EUROPE GmbH".Its registered office is at Teilestrasse 11-16,12099 Berlin,Germany.IKAD EUROPE GmbH is a voting member in the trade section of "FCC-The Federation of Cocoa Commerce Ltd."</span> :
              <span className="text-left" > Die Gesellschaft wurde am 19<sup>th</sup>. Dezember 2003 unter der Firmierung "IKAD EUROPE GmbH" gegründet. Ihr Geschäftssity befindet sich in der Teilstraße 11-16,12099 Berlin, Deutschland. IKAD EUROPE GmbH ist ein stimmberechtigtes Mitglied der Sektion "Handel" der "FCC-The Federation of Cocoa Commerce Ltd."</span>
             }
            </Col>
          </Row>
       
        </Col>
      </Row>
      <Row className="justify-content-center prov-section">
       <Col xs={12} className="prov_img">

       </Col>
       <Col xs={12} className="text-section">
         {en?<Fragment> <p>WE PROVIDE</p><span>The best quality of food and cocoa products
                 and also machine components</span></Fragment>:
                 <Fragment> <p>WIR BIETEN AN</p>
                    <span> Die beste Qualität von Lebensmitteln und Kakaoprodukten sowie Maschinenkomponenten</span>
                   </Fragment>
         
         
        } 
                 <Row className="btn-section">
                 <Link  className="btn btn-larg  btn-info"  to="/Contact" > {en?'CONTACT WITH US':'Kontaktieren Sie uns'}</Link>
                 </Row>
                
       </Col>
      </Row>
       
      <Row className="justify-content-center Service-section">
        <Col sm={12} md="2"> 
        {en?
        <p> OUR SERVICES</p>:
        <p>Unsere Dienstleistungen</p>}</Col>
       
      </Row>
      <Row className="justify-content-center  card-section text-left">
         <Col sm={12} md={12} lg={10} xl={10}>
         <CardGroup>
      <Card>
        <CardImg top width="100%" src={coffeeimg} alt="Card image cap" />
        <CardBody>
          <CardTitle tag="h5">Import and Export cocoa</CardTitle>         
         {/*   <CardText className="text-left  pl-3">best quality of cocoa seeds and powder and cocoa butter</CardText>*/}
         {en?<Link to="/Services">Read More ...</Link>:<Link to="/Services">Mehr erfahren ...</Link>} 
        </CardBody>
      </Card>
      <Card>
        <CardImg top width="100%" src={foodimg} alt="Card image cap" />
        <CardBody>
          <CardTitle tag="h5">Import and Export Food</CardTitle>         
          {/*  <CardText className="text-left pl-3">Ikad Europe Company produces healthy food products and supplies high-quality organic raw materials.</CardText>*/}
          {en?<Link to="/Services">Read More ...</Link>:<Link to="/Services">Mehr erfahren ...</Link>} 
        </CardBody>
      </Card>
      <Card>
        <CardImg top width="100%" src={machineryimg} alt="Card image cap" />
        <CardBody>
          <CardTitle tag="h5">Import and Export machinery</CardTitle>        
         {/* <CardText className="text-left  pl-3"> design and construction coordination (without own skilled manual works) as well as the purchase and sale of machinery, machine components, machine spare parts, industrial facilities and factories and the provision of general advisory servises in this area.</CardText>*/}
         {en?<Link to="/Services">Read More ...</Link>:<Link to="/Services">Mehr erfahren ...</Link>} 
        </CardBody>
      </Card>
    </CardGroup>
         </Col>
       </Row>
    </Fragment>
  )
}

export default Home;